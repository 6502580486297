import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

const noRecordStyles = makeStyles(() =>
  createStyles({
    noRecordRoot: {
      margin: "auto",
      textAlign: "center",
      "& .top": {
        marginBottom: 20,
      },
      "& .bottom": {
        marginTop: 40,
      },
    },
  }),
);
export default noRecordStyles;
