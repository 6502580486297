import React from "react";
import noRecord from "../../images/norecordicon.svg";
import noRecordStyle from "./noRecordStyle";


const NoRecord = ({ description, position = "bottom" }) => {
  const classes = noRecordStyle();
  return (
    <div className={classes.noRecordRoot}>
      {position === "top" && (
        <div className="top" style={{ color: "#aba4a4" }}>
          <p>{description}</p>
        </div>
      )}
      <div>
        <img
          src={noRecord}
          className="mt-3"
          alt="no record"
          style={{ width: "100px" }}
        />
      </div>
      {position === "bottom" && (
        <div className="bottom" style={{ color: "#aba4a4" }}>
          <p>{description}</p>
        </div>
      )}
    </div>
  );
};

export default NoRecord;
