import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { createStyles } from "@material-ui/styles";
import React from "react";
const institutionLoaderStyles = makeStyles(() =>
  createStyles({
    institutionLoaderRoot: {
      "& span": {
        borderRadius: 8,
      },
    },
  }),
);

export const InstitutionLoader = () => {
  const classes = institutionLoaderStyles();
  return (
    <div className={classes.institutionLoaderRoot}>
      <Skeleton variant="rect" width={"100%"} height={150} />
    </div>
  );
};
