import { TextField, TextFieldProps } from "@material-ui/core";
import redcircle from "../../images/redcircle.svg";
import clsx from "clsx";
import React from "react";
import customInputStyle from "./customInputStyle";


const CustomInput = ({
  labelText,
  errorText,
  value,
  endIcon,
  placeholder = "Text Input",
  type = "text",
  showError = false,
  disabled = false,
  textfieldProps,
  id = "",
  startIcon,
  inputHeight,
  className,
  rows,
  name,
  handleChange,
  inputWidth,
  customButtonText,
  backgroundColor,
  multiline,
  defaultValue,
  loading,
  border,
  textColor,
  showDefaultBorder = true,
  position = "absolute",
}) => {
  const classes = customInputStyle();
  return (
    <div className={classes.inputContainer}>
      {labelText && (
        <label
          // style={{ position: position }}
          className={clsx(classes.inputLabel)}
          htmlFor={id}
        >
          {labelText}
        </label>
      )}{" "}
      <TextField
        id={id}
        defaultValue={defaultValue}
        InputProps={{
          style: {
            color: textColor,
            border: border,
            marginTop: 0,
          },
          defaultValue,
          startAdornment: startIcon,
          endAdornment:
            endIcon ||
            (loading && (
              <div className="d-flex justify-content-center">
                {startIcon && <div>{startIcon}</div>}
              </div>
            )),
          className: className,
          classes: {
            root: clsx(
              classes.root,
              !showDefaultBorder && classes.rootNoborder,
            ),
            input: multiline ? classes.multilineInput : classes.input,
            notchedOutline: classes.notchedOutline,
          },
        }}
        inputProps={{
          style: {
            height: inputHeight,
            width: inputWidth,
            borderRadius: 4,
            backgroundColor: backgroundColor,
          },
        }}
        minRows={rows}
        name={name}
        {...textfieldProps}
        onChange={handleChange}
        error={showError}
        autoComplete="new-password" //to disable autocomplete
        variant="outlined"
        value={value}
        multiline={multiline}
        margin="normal"
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        fullWidth
      />
      {showError && (
        <div className={classes.inputErrorContainer}>
          <div className={classes.inputerrorIcon}>
            <img src={redcircle} />
          </div>
          <div>
            {" "}
            <small className={classes.errorLabel}>{errorText}</small>
          </div>
        </div>
      )}
      {customButtonText && (
        <div className={classes.inputErrorContainer}>
          <div className={classes.inputerrorIcon}></div>
          <div> {customButtonText}</div>
        </div>
      )}
    </div>
  );
};
export default CustomInput;
