import { makeStyles } from "@material-ui/core/styles"
import banner from "../../../images/banner.png"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#a0a0a02b",
    // paddingBottom: "3rem"
  },
  hero: {
    paddingTop: "4rem",
    paddingBottom: "2rem",
    backgroundColor: "#FFFFFF"
  },
  body: {
    padding: "0 0 10rem 0",
    "& p, li": {
      fontWeight: 400,
      fontSize: `1rem`,
    },
  },
  banner: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: '550px',
    backgroundSize: "cover",
    backgroundImage: `url(${banner})`
  },
  subTitle: {
    padding: "0 2rem",
  },
  formControl: {
    width: "100%",
  },
  label: {
    fontSize: "14px",
    fontWeight: "400",
    marginBottom: ".3rem",
  },
  action: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  textField: {
    height: "2.5rem",
    padding: "0 1rem",
  },
  introOptions: {
    display: "flex",
    flexDirection: "column",
  },
  card: {
    background: "#FFFFFF",
    // boxShadow: "0px 4px 18px rgba(143, 148, 155, 0.16)",
    borderRadius: "8px",
    // maxWidth: "370px",
    // margin: '1rem 10px',
    padding: '20px',
    // overflowX: 'scroll',

    display: "inline-block",
    verticalAlign: "top",
    margin: "10px",
    whiteSpace: "normal",
    // height: '350px',
  },
  cardContainer: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
  },
  button: {
    width: '150px'
  },
  // cardContainer: {
  //   display: 'flex',
  //   paddingTop: '2rem'
  // }
}))

export { useStyles }
