import { makeStyles } from "@material-ui/core";
const customInputStyle = makeStyles((theme) => ({
  root: {
    borderRadius: "6px",
    marginBottom: "0px",
    marginTop: 12,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      boxShadow: "0px 4px 10px 3px rgba(0, 0, 0, 0.11)",
    },
    color: `${theme.palette.primary.main} !important`,
    border: `1px solid ${theme.palette.primary.dark}`,
    backgroundColor: "white !important",
    "& :active": {
      border: "none !important",
    },
    "&:-webkit-autofill": {
      // WebkitBoxShadow: theme.palette.type === 'dark' ? '0 0 0 100px #266798 inset' : 'white',
      // WebkitTextFillColor: theme.palette.type === 'dark' ? '#fff' : 'black',
      //   borderRadius: theme.shape.borderRadius,
    },
    "& .input-type-toggle": {
      color: theme.palette.primary.light,
    },
  },
  rootNoborder: {
    border: `1px solid ${theme.palette.secondary.main} !important`,
  },
  textField: {},
  inputErrorContainer: {
    display: "flex",
    marginTop: "-7px",
  },
  multilineInput: {
    paddingLeft: "15px",
  },
  input: {
    paddingLeft: "28px !important",
    paddingTop: "14px !important",
    paddingBottom: "14px !important",
    [theme.breakpoints.down("xl")]: {
      // width:300
    },
  },
  inputLabel: {
    fontSize: 14.5,
    fontWeight: 400,
    // position: "absolute",
    // marginBottom:10,
    color: `${theme.palette.primary.main} !important`,
  },
  notchedOutline: {
    top: 0,
    borderColor: `rgba(0, 0, 0, 0.093)`,
    border: "none !important",
  },
  formControl: {
    // width:'100%'
  },
  endIcon: {
    marginTop: "-52px",
    position: "absolute",
    right: "7px",
    cursor: "pointer",
  },
  errorLabel: {
    color: theme.palette.warning.main,
    marginLeft: 5,
  },
  inputerrorIcon: {
    marginTop: -1,
  },
  inputContainer: {
    // marginBottom: "8px",
    "& .MuiFormControl-marginNormal": {
      marginTop: 7,
    },
  },
}));

export default customInputStyle;
