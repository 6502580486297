import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
// import { landingPadding } from "../../constants";
// import { ITheme } from "../../theme";

const landingPadding = 160;
const institutionStyle = makeStyles((theme) =>
    createStyles({
        rootSection: {
            backgroundColor: theme.palette.primary.contrastText,

            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(8),
            [theme.breakpoints.down("md")]: {
                paddingLeft: theme.spacing(2),
            },
            "& h4": {
                paddingLeft: landingPadding,
                paddingBottom: theme.spacing(0.5),
                [theme.breakpoints.down("md")]: {
                    paddingLeft: theme.spacing(0),
                    paddingRight: theme.spacing(0),
                    width: "max-content",
                    paddingBottom: theme.spacing(3),
                },
            },
            " & .courseCount": {
                marginTop: 7,
                marginLeft: 15,
                [theme.breakpoints.down("xs")]: {
                    marginTop: 5,
                },
                color: theme.palette.grey[500],
            },
        },
        searchSection: {
            paddingRight: landingPadding - 50,
            "@media screen and (min-width: 720px) and (max-width: 1700px)": {
                paddingRight: 0,
            },
            "& .searchSectionRight": {
                // marginTop: -20,
                "& .searchSectionRightRoot": {
                    display: "flex",
                    [theme.breakpoints.down("xs")]: {
                        flexWrap: "wrap",
                        marginTop: 10,
                    },
                    [theme.breakpoints.up("md")]: {},
                },
                "& .searchInput": {
                    marginRight: 30,
                    [theme.breakpoints.down("xs")]: {
                        width: "max-content",
                    },
                },
                "& .filterButton": {
                    alignSelf: "center",
                },
            },
        },
        innerSection: {
            paddingLeft: landingPadding,
            paddingRight: landingPadding,
            marginTop: theme.spacing(5),
            [theme.breakpoints.down("md")]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
        },
    }),
);
export default institutionStyle;
