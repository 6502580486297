import React, { useContext, useEffect, useState } from "react"
import {
  Typography,
  Box,
  FormControl,
  Grid,
  TextField,
  useMediaQuery,
  Container
} from "@material-ui/core"
import { useTheme } from "@material-ui/styles"
import * as Yup from "yup"
import { useStyles } from "./style"

import Button from "../../Button"
import GLOBAL from "../../../images/GLOBAL.png"
import ArrowRight from "../../../images/ArrowRight.svg"
import ArrowLeft from "../../../images/ArrowLeft.svg"

import LoanContext from "../../../context/loan/loanContext"
import InstitutionsList from "../../AllInstitutions/InstitutionList"
// import InstitutionContext from "../../../context/institution/institutionContext"
// import AlertContext from "../../../context/alert/alertContext"


const ScholarshipLayout = () => {
  // const { isLoading, refer } = useContext(LoanContext)
  // const { setAlert } = useContext(AlertContext)
  const { getAllInstitutionsApiService } = useContext(LoanContext)

  useEffect(() => {
    async function loadInstitutions() {
      try {
        const res = await getAllInstitutionsApiService()
        // setPartners(res)
      } catch (err) {
        console.log(err)
      }
    }
    loadInstitutions()
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [])

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const classes = useStyles()


  return (
    <Box
      id="refer"
      position="relative"
      paddingTop={isMobile ? "5rem" : "6.5rem"}
      className={classes.root}
    >
      <Box className={classes.hero}>
        <Typography variant="h2" align="center" gutterBottom>
          Scholarships
        </Typography>
        <Typography className={classes.subTitle} align="center">
          We empower people to make a difference in lives of students across the globe by providing easy and   {!isMobile && <br />}
          instant access to educational loans and other supportive services
        </Typography>
      </Box>

      <Box className={classes.banner}>

      </Box>

      {/* <Box className={classes.controls}>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            width: "100%",
            margin: "1.4rem auto 1rem",
            paddingRight: "100px"
          }}
        >
          <img src={ArrowLeft} onClick={slideLeft} style={{ cursor: 'pointer', marginRight: '30px', width: "40px" }} />
          <img src={ArrowRight} onClick={slideRight} style={{ cursor: 'pointer', width: "40px" }} />
        </div>
      </Box> */}

      <InstitutionsList />
    </Box>
  )
}

export default ScholarshipLayout
