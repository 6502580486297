import { Grid, useMediaQuery, useTheme } from "@material-ui/core"
import searchicon from "../../images/searchicon.svg"
// import { initPager } from "constants/dummyRequests";
// import useMobile from "hooks/useMobile";
// import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react"
// import { getAllInstitutionsApiService } from "services/institutions";
import courseListStyles from "./institutionStyle"
// import { IInstutions } from "types/institutions";
// import { IListMetaData } from "types/listtable";
// import { IPager } from "types/Pagination";
// import { IRequests } from "types/requests";
import { useDebounce } from "use-debounce"
import CustomInput from "../../components/CustomInput/CustomInput"
// import VigilearnPaginationApi from "../../ui/Pagination/VigilearnPaginationApi";
import ListError from "../Error/ListError"
import NoRecord from "../Error/NoRecord"
import { InstitutionCard } from "./InstitutionCard"
import { InstitutionLoader } from "./InstitutionLoader"
import LoanContext from "../../context/loan/loanContext"
import VigilearnPaginationApi from "../Pagination/VigilearnPaginationApi"

const InstitutionsList = () => {
  const initPager = {
    totalItems: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    startPage: 1,
    endPage: 0,
    startIndex: 0,
    endIndex: 0,
    pages: [],
    loadingItems: true,
    errorInLoading: false,
  }

  const { getAllInstitutionsApiService } = useContext(LoanContext)

  const classes = courseListStyles()
  // const router = useRouter();
  const [pageOfItems, setpageOfItems] = useState([])
  const [pager, setpager] = useState(initPager)
  const [listMetaData, setlistMetaData] = useState()
  // const { isMobile } = useMobile();
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))
  const [institutionsearchData, setinstitutionsearchData] = useState({
    pageSize: 12,
  })
  const [institutionsearchKeyword, setinstitutionsearchKeyword] = useState()
  const [institutionsearchDataKeyword] = useDebounce(
    institutionsearchKeyword,
    700
  )

  useEffect(() => {
    // the search keyword has been changed...
    if (institutionsearchDataKeyword || institutionsearchDataKeyword === "") {
      setinstitutionsearchData(prevState => ({
        ...prevState,
        searching: true,
        searchWord: institutionsearchKeyword,
      }))
    }
  }, [institutionsearchDataKeyword])
  const handleChangePageParent = (newPage, pageOfItems, newPager) => {
    setpageOfItems(pageOfItems)
    setpager(newPager)
  }

  const updateSearch = listMetaData => {
    setlistMetaData(listMetaData)
  }
  const handleSearchInputChange = e => {
    setinstitutionsearchKeyword(e.target.value)
  }

  const handleViewCourses = item => {
    // router.push(`institution/${item.institutionCode}`);
  }
  return (
    <div className={classes.rootSection}>
      <Grid container className={classes.searchSection}>
        <Grid item md={8}>
          <div className="d-flex justify-content-start">
            <h4>
              Institutions{" "}
              <span className="courseCount">{pager.totalItems}</span>
            </h4>
          </div>
        </Grid>

        <Grid item md={3} className="searchSectionRight">
          <div className="searchSectionRightRoot">
            <div className="searchInput">
              <CustomInput
                endIcon={<img src={searchicon} alt="searchicon" />}
                placeholder="Search institutions"
                inputHeight={10}
                handleChange={e => handleSearchInputChange(e)}
                showDefaultBorder={false}
                inputWidth={isMobile ? 290 : 300}
              />
            </div>
            <div className="filterButton">
              {/* <CustomActionButton
                                startIcon={<img src={filtericon} />}
                                border
                                text="Filter by"
                                show={true}
                                width={120}
                            /> */}
            </div>
          </div>
        </Grid>
      </Grid>
      <div className={classes.innerSection}>
        <Grid container spacing={3}>
          {!listMetaData?.isSearching &&
            !listMetaData?.isError &&
            pageOfItems?.length > 0 &&
            pageOfItems.map((item, index) => (
              <Grid item md={3} xs={12} className="ps-0" key={index}>
                <InstitutionCard
                  item={item}
                  handleViewCourses={handleViewCourses}
                />
              </Grid>
            ))}

          {listMetaData?.isSearching &&
            [1, 2, 3, 4].map(index => (
              <Grid item md={3} xs={12} className="ps-0" key={index}>
                <InstitutionLoader />
              </Grid>
            ))}

          {!listMetaData?.isSearching &&
            !listMetaData?.isError &&
            pageOfItems?.length === 0 && (
              <div className="ps-0 m-auto">
                <NoRecord description="No institutions found.." />
              </div>
            )}
          {!listMetaData?.isSearching && listMetaData?.isError && (
            <div className="ps-0 m-auto">
              <ListError />
            </div>
          )}
        </Grid>

        <div className="w-100">
          <VigilearnPaginationApi
            itemsCount={pager.totalItems}
            handleChangePageParent={handleChangePageParent}
            items={pageOfItems}
            searchData={institutionsearchData}
            updateSearch={updateSearch}
            listMetaData={listMetaData}
            service={getAllInstitutionsApiService}
          />
        </div>
      </div>
    </div>
  )
}
export default InstitutionsList
