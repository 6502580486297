import {
  CardActionArea,
  CardMedia,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { createStyles } from "@material-ui/styles"
import sampleuniversity from "../../images/sampleuniversity.svg"
import clsx from "clsx"
import { Link } from "gatsby"
import React, { useContext, useState } from "react"
import Button from "../Button"
import AuthContext from "../../context/auth/authContext"
// import { ITheme } from "theme";
// import { IInstutions } from "../../../types/institutions";
// import CustomButton from "../../ui/CustomButton/CustomButton";

const institutionCardStyles = makeStyles(() =>
  createStyles({
    popularCoursesRoot: {},
    root: {
      borderRadius: 15,
      height: 200,
      border: `1px solid grey`,
    },

    content: {
      paddingLeft: 8,
      color: "white",
      // backgroundColor: theme.palette.primary.contrastText,
      textAlign: "center",
      "& img": {
        textAlign: "center",
        width: "30%",
      },

      "& .name": {
        marginTop: 20,
        color: "white",
        fontWeight: 500,
        fontSize: 23,
        lineHeight: 1,
      },
    },
    backdrop: {
      height: 200,
      backgroundSize: "cover",
      transition: "all 0.2s ease-in-out",
    },
    cardContent: {
      height: "120px",
      backgroundColor: "rgba(241, 255, 255, 0.82)",
      color: "#24292E",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "24px",
    },
    cardBody: {
      maxWidth: "250px",
      "&:hover": {
        border: "1px solid #1943D0",
        "& .cardContent2": {
          backgroundColor: "#1943D0",
          color: "#fff",
        },
      },
    },
  })
)

// export interface InstitutionCardProps {
//   item: IInstutions;
//   handleViewCourses?: (item: IInstutions) => void;
// }

export const InstitutionCard = ({ handleViewCourses, item }) => {
  const { isAuthenticated } = useContext(AuthContext)
  const link = isAuthenticated ? "/dashboard" : "/user/register"
  const classes = institutionCardStyles()
  const [style, setStyle] = useState({ display: "block" })

  const handleLearnMore = code => {
    var link = `${process.env.MARKETPLACE_BASE_URL}/institution/${code}`

    window.open(link, "_blank")
  }
  // const appTheme = useTheme();
  return (
    <>
      <Card className={classes.cardBody}>
        <CardActionArea onClick={() => handleLearnMore(item.institutionCode)}>
          <CardMedia
            component="img"
            height="120px"
            image={item.logoUrl || sampleuniversity}
            alt="logo"
            style={{ objectFit: "fill" }}
          />
          <CardContent className={`${classes.cardContent} cardContent2`}>
            {style.display === "none" && (
              <div>
                <img
                  src={item.logoUrl || sampleuniversity}
                  alt="universitylogo"
                />
              </div>
            )}
            {style.display === "block" && (
              <div>
                <Typography gutterBottom component="div">
                  {item.name}
                </Typography>
              </div>
            )}
          </CardContent>
        </CardActionArea>
      </Card>

      {/* <Card
        // onMouseLeave={e => {
        //   setStyle({ display: "none" });
        // }}
        // onMouseEnter={() => {
        //   setStyle({ display: "block" });
        // }}
        className={clsx(classes.root)}
        elevation={0}
      >
        <div>
          <CardContent
            style={{
              backgroundBlendMode: "overlay",
              backgroundColor: "#0000002e",
              backgroundImage: `url(${
                item.logoUrl || sampleuniversity
              }), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.380186) 62.33%, rgba(0, 0, 0, 0.61) 100%)`,
            }}
            className={clsx(
              classes.content,
              style.display === "block" && classes.backdrop
            )}
          >
            {style.display === "none" && (
              <div>
                <img
                  src={item.logoUrl || sampleuniversity}
                  alt="universitylogo"
                />
              </div>
            )}
            {style.display === "block" && (
              <div>
                {" "}
                <p className="name">{item.name}</p>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleLearnMore?.(item.institutionCode)}
                  textAlign="center"
                >
                  <span style={{ margin: "0.4rem 1.3rem", fontSize: "0.8vw" }}>
                    {" "}
                    Learn More{" "}
                  </span>
                </Button>
              </div>
            )}
          </CardContent>
        </div>
      </Card> */}
    </>
  )
}
